









































































































import { newCarModel } from '@/components/molecules/select-search/store/carModele'
import { CarModel } from '@/api/interfaces/carmodel'
import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'

export default defineComponent({
  name: 'SelectSearchCarModel',
  components: {
    SelectSearch: () => import('../m-select-search.vue'),
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null,
    },
    carBrand: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data () {
    return {
      dialogCarModel: false,
      isSubmiting: false,
      carModel: newCarModel() as CarModel,
    }
  },
  watch: {
    carBrand (brand) {
      if (brand) {
        this.carModel.carBrand = this.carBrand['@id']
        this.load(brand)
      }
      if (this.value && this.carModelList.length > 0) {
        this.$emit('input', this.findCarModelInList(this.value['@id']))
      }
    },
  },
  created () {
    if (this.carBrand) this.load(this.carBrand)
  },
  computed: {
    ...mapState('carModeleList', {
      carModelList: 'list',
    }),
  },
  methods: {
    ...mapActions('carModeleList', {
      load: 'load',
      save: 'save',
    }),
    itemText (item: CarModel) {
      // use this function instead of arrow function in item-text prop, workaround for https://github.com/vuetifyjs/vuetify/issues/11585
      return item.model
    },
    onInput (uri: string) {
      const carModel = this.findCarModelInList(uri)
      this.$emit('input', carModel)
    },
    findCarModelInList (uri: string) {
      return (this.carModelList as Array<CarModel>).find(x => x['@id'] === uri)
    },
    getValidationObserver () {
      return this.$refs.observer as InstanceType<typeof ValidationObserver>
    },
    async onSubmit () {
      this.isSubmiting = true
      try {
        const newCarModel = await this.save(this.carModel)
        this.$emit('input', newCarModel)
        this.clear()
        this.dialogCarModel = false
      } finally {
        this.isSubmiting = false
      }
    },
    clear () {
      this.carModel = newCarModel()
      this.carModel.carBrand = this.carBrand ? this.carBrand['@id'] : null
      this.getValidationObserver().reset()
    },
    createModel () {
      this.dialogCarModel = !this.dialogCarModel
    },
  },
})
